@import 'styles/variables.scss';

.imageHeight {
  height: 14rem;

  @media (min-width: $screens-md) {
    height: 12rem;
  }

  @media (min-width: $screens-lg) {
    height: 9.5rem;
  }

  @media (min-width: $screens-xl) {
    height: 12rem;
  }
}
