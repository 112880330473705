@import 'styles/variables.scss';

.hero {
  margin-inline: auto;

  .heroGrid {
    @media (min-width: $screens-lg) {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      overflow: hidden;
    }

    .heroLeftColumn {
      background-color: #155263;
      display: flex;
      flex-direction: column;
      padding: 40px 16px;

      .image {
        align-self: flex-start;

        img {
          width: 150px;
        }
      }

      @media (min-width: $screens-lg) {
        padding-inline: 84px;

        .image {
          align-self: center !important;

          img {
            width: 350px !important;
          }
        }
      }

      .intro {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .title {
          color: var(--color-white);
          font-size: 48px;
          font-family: var(--fontFamily-netto);
          line-height: 64px;
        }

        .subtitle {
          color: var(--color-white);
          font-family: var(--fontFamily-adelle);
          font-size: 20px;
          font-weight: 700;
          line-height: 28px;
        }

        .list {
          color: var(--color-white);
          font-family: var(--fontFamily-adelle);
          font-size: 16px;
          line-height: 28px;
          display: none;

          @media (min-width: $screens-lg) {
            display: block;
          }

          ul {
            display: flex;
            flex-direction: column;
            gap: 6px;

            li {
              display: flex;
              gap: 12px;
            }
          }
        }
      }

      .checkQuonosuiteButton {
        align-self: flex-start;

        & > span {
          background-color: var(--color-navy);
          border: 1px solid var(--color-white);
          color: var(--color-white);
          width: 100%;

          @media (min-width: $screens-lg) {
            width: 300px;
          }
        }
      }
    }

    .heroRightColumn {
      background-color: #fff2d5;
      display: flex;
      flex-direction: column;
      padding: 40px 16px;

      .image {
        align-self: flex-start;

        img {
          width: 150px;
        }
      }

      @media (min-width: $screens-lg) {
        padding-inline: 84px;

        .image {
          align-self: center !important;

          img {
            width: 310px !important;
          }
        }
      }

      .intro {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .title {
          color: var(--color-navy);
          font-size: 48px;
          font-family: var(--fontFamily-netto);
          line-height: 64px;
        }

        .subtitle {
          color: var(--color-navy);
          font-family: var(--fontFamily-adelle);
          font-size: 20px;
          font-weight: 700;
          line-height: 28px;
        }

        .list {
          color: var(--color-charcoal);
          font-family: var(--fontFamily-adelle);
          font-size: 16px;
          line-height: 28px;
          display: none;

          @media (min-width: $screens-lg) {
            display: block;
          }

          ul {
            display: flex;
            flex-direction: column;
            gap: 6px;

            li {
              display: flex;
              gap: 12px;
            }
          }
        }

        .getStartedButton {
          align-self: flex-start;

          & > span {
            width: 100%;

            @media (min-width: $screens-lg) {
              width: 300px;
            }
          }
        }
      }
    }
  }
}
