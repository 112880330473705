@import 'styles/variables.scss';

.container {
  background-color: var(--color-secondary-bright);
  margin-bottom: var(--spacing-12);
  padding-block: var(--spacing-10);

  @media (min-width: $screens-md) {
    padding-block: var(--spacing-12);
  }
}

.headline {
  color: var(--color-navy);
  font-weight: 700;
  margin-bottom: var(--spacing-2);
  text-transform: uppercase;
}

.input {
  background-color: var(--color-secondary-bright);
  border-bottom: 1px solid var(--color-silverLight);
  flex: 1 1 0%;
  font-size: var(--text-2xl-size);
  width: 100%;

  @media (min-width: $screens-md) {
    margin-left: var(--spacing-6);
  }

  @media (min-width: $screens-lg) {
    font-size: var(--text-3xl-size);
  }
}

.inputWrapper {
  @media (min-width: $screens-md) {
    display: flex;
  }
}

.label {
  display: block;
  color: var(--color-navy);
  font-family: var(--fontFamily-netto);
  font-size: var(--text-2xl-size);
  margin-bottom: var(--spacing-3);
  margin-top: var(--spacing-5);

  @media (min-width: $screens-md) {
    margin-block: 0;
  }

  @media (min-width: $screens-lg) {
    font-size: var(--text-3xl-size);
  }
}

.popularHeadline {
  color: var(--color-navy);
  font-weight: 700;
  margin-bottom: var(--spacing-6);
  margin-top: var(--spacing-12);
  text-transform: uppercase;
}

.popularItem {
  background-color: var(--color-white);
  border: 1px solid var(--color-silverLight);
  border-radius: 9999px;
  cursor: pointer;
  margin-block: var(--spacing-2);
  margin-right: var(--spacing-4);
  padding-block: var(--spacing-2);
  padding-inline: var(--spacing-4);
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  white-space: nowrap;

  &:hover {
    background-color: var(--color-secondary);
  }
}

a.popularLink {
  color: var(--textColor-primary);
  font-family: var(--fontFamily-adelle);
  text-decoration: none;
  text-transform: none;
}

.popularList {
  display: flex;
  margin-bottom: var(--spacing-6);
  overflow-x: auto;

  @media (min-width: $screens-md) {
    flex-wrap: wrap;
  }
}

.wrapper {
  margin-inline: auto;
  max-width: $screens-xl;
  padding-inline: var(--spacing-5);
}
