.card {
  display: flex;
  align-items: center;
  gap: var(--spacing-4);
}

.name {
  color: var(--color-navy);
  font-weight: 700;
}

.description {
  font-size: var(--text-sm-size);
}
