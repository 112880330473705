.container {
  position: relative;
}

.image {
  height: inherit;
  object-fit: cover;
}

.square .image {
  border-radius: var(--spacing-2);
}

.circle .image {
  border-radius: 9999px;
}
