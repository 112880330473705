@import 'styles/variables.scss';

.contactButton {
  margin-right: var(--spacing-1);

  @media (min-width: $screens-md) {
    display: none !important;
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-inline: auto;
  max-width: $screens-xl;

  @media (min-width: $screens-lg) {
    padding-inline: var(--spacing-4);
  }
}

.conversionBar {
  background-color: var(--color-white);
  border-top-left-radius: var(--spacing-2);
  border-top-right-radius: var(--spacing-2);
  bottom: 0;
  left: 0;
  max-height: 100vh;
  overflow-y: auto;
  padding: var(--spacing-2) var(--spacing-6) var(--spacing-5);
  position: fixed;
  right: 0;
  transform: translateY(100%);
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
  box-shadow: 0 var(--spacing-0-5) var(--spacing-1-5) rgba(176, 186, 197, 0.600289);
  z-index: 501;

  @media (min-width: $screens-md) {
    border-radius: 0;
    padding: var(--spacing-3);
  }
}

.getAQuote {
  display: flex;
  flex-shrink: 0;
  justify-content: flex-end;
  margin-left: var(--spacing-1);
  width: 50% !important;

  @media (min-width: $screens-md) {
    width: auto !important;
  }
}

.patientManager {
  display: none;
  width: 100%;

  @media (min-width: $screens-md) {
    display: block;
  }
}

/**
 * Fade transition classes
 */
.fade {
  opacity: 0;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  transition-duration: 150ms;
  will-change: opacity;
}

.fadeEnter {
  opacity: 0;
  transition-duration: 300ms;
}

.fadeEnterActive {
  opacity: 0.75;
}

.fadeEnterDone {
  opacity: 0.75;
  pointer-events: auto;
}

.fadeExit {
  transition-duration: 500ms;
}

.fadeExitActive {
  opacity: 0;
}

.fadeExitDone {
  opacity: 0;
}
